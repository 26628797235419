/**
 * Boards
 * ======================================
 * - replace video source with [data-file] attr
 */

 const ELEMENTS = ".js-boards .boards__list-item";
 const VIDEO = ".js-boards-video";

 class Boards {
   constructor() {
     this.elements = document.querySelectorAll(ELEMENTS);
     this.movies = document.querySelectorAll(VIDEO)

     if (!this.elements) {
       return false;
     }

     this.elements.forEach((el) => {
       el.addEventListener("mouseover", this.hoverHandler);
     });
   }

   setFile = (index) => {
    const movie = document.querySelector(VIDEO + '[data-index="'+index+'"]')
    const currentMovie = document.querySelector(VIDEO + '.is-active')

    if(movie != currentMovie) {
      movie.currentTime = currentMovie.currentTime
      movie.play()

      currentMovie.pause()

      setTimeout(() => {
        movie.classList.add('is-active')
        currentMovie.classList.remove('is-active')
      }, 100);
    }
   }

   debounce = (callback, wait) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => callback(...args), wait);
    };
  }

   debouncedSetFile = this.debounce(this.setFile, 100)

   hoverHandler = (e) => {
     const el = e.currentTarget;
     const file = el.getAttribute('data-index');

     this.debouncedSetFile(file)
   };

 }

 new Boards();
