
/**
 * Fortune wheel
 *
 * $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
 */

 const ELEMENT = '.js-daytona'
 const ROTATE_ELEMENT = '.fortune-wheel__text'
 var isScrolling

 class FortuneWheel {
   constructor() {
     this.wheel = document.querySelector(ELEMENT)

     if(!this.wheel){
       return false
     }

     this.rotateElement = this.wheel.querySelector(ROTATE_ELEMENT)
     this.scrollRatio = 0.13

     if(!this.isTouchDevice()){
      window.addEventListener('scroll', this.scrollHandler)

      // this.scrollStop(this.rotateEnd);
     }
   }

   isTouchDevice = () => {
     return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
   }

   scrollHandler = () => {
     const top = document.documentElement.scrollTop;

     this.rotate(top)
   }

   scrollStop = (callback, refresh = 66) => {
    if (!callback || typeof callback !== 'function') return;
    let isScrolling;
    window.addEventListener('scroll', function (event) {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(callback, refresh);
    }, false);
  }

  rotateEnd = () => {
    const top = document.documentElement.scrollTop;

    this.rotate(top + 100)
  }

   rotate = (top) => {
    const rotation = (this.scrollRatio * top).toFixed(2)

    this.rotateElement.setAttribute('style', 'transform: rotate('+rotation+'deg)')
   }
 }

 new FortuneWheel()
